import React from 'react';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import person from '../assets/person.png'; // User avatar
import mindhyveLogo from '../assets/mindhyveLogo.png'; // AI avatar

const ChatMessage = ({ message }) => {
  const { text, ai, createdAt } = message;
  const isHuman = !ai; // Determine if the message is from the user

  return (
    <div className={`message-group mb-6 ${isHuman ? 'justify-end' : 'justify-start'} flex w-full`}>
      {/* Conditionally render the message based on whether it's human or AI */}

      {/* If it's a human message (align right) */}
      {isHuman && (
        <div className="flex justify-end mb-4 w-full max-w-full">
          <div className="message__wrapper max-w-[70%] text-white rounded-lg p-3">
            <div className="message__markdown text-right">
              <ReactMarkdown>{text}</ReactMarkdown>
            </div>
            <div className="message__createdAt text-xs mt-1 text-gray-500 text-right">
              {moment(createdAt).calendar()}
            </div>
          </div>
          <div className="message__pic ml-3">
            <div className="avatar">
              <div className="w-8 border rounded-full">
                <img src={person} alt="User avatar" />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* If it's an AI message (align left) */}
      {!isHuman && (
        <div className="flex justify-start mb-4 w-full max-w-full">
          <div className="message__pic mr-3">
            <div className="avatar">
              <div className="w-8 border rounded-full">
                <img src={mindhyveLogo} alt="AI avatar" />
              </div>
            </div>
          </div>
          <div className="message__wrapper max-w-[70%] bg-gray-200 text-black rounded-lg p-3">
            <div className="message__markdown text-left">
              <ReactMarkdown>{text}</ReactMarkdown>
            </div>
            <div className="message__createdAt text-xs mt-1 text-gray-500">
              {moment(createdAt).calendar()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatMessage;
