import React, { createContext, useContext, useState } from 'react';

const ChatContext = createContext();

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChatContext must be used within a ChatContextProvider');
  }
  return context;
};

export const ChatContextProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [defaultThread, setDefaultThread] = useState(null);
  const [isGeneratingAnswer, setIsGeneratingAnswer] = useState(false); // New state to track loading

  const clearMessages = () => setMessages([]);

  return (
    <ChatContext.Provider
      value={{
        messages,
        setMessages,
        clearMessages,
        defaultThread,
        setDefaultThread,
        isGeneratingAnswer, // Expose this in the context
        setIsGeneratingAnswer, // Expose this in the context
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
