import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../config/authConfig'; // Make sure this file exists and is properly configured
import mindhyveLogo from '../assets/mindhyveLogo.png';
const VERSION = '0.1.0';

const RealSignUp = () => {
  const { instance } = useMsal();

  const handleLoginWithMicrosoft = async (event) => {
    event.preventDefault(); // Prevent form submission
    try {
      await instance.loginRedirect({
        ...loginRequest,
        redirectStartPage: '/threads/', // This will redirect to /login after successful authentication
      });
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div className="relative min-h-full flex-1 bg-white h-[100vh] flex-col justify-center px-6 py-12 lg:px-8">
      {/* Version display at the top-right */}
      <div className="absolute top-4 right-8">
        <div className="version-display rounded-md text-black p-1 bg-fade-white text-xs font-bold">
          Version: {VERSION}
        </div>
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img alt="Your Company" src={mindhyveLogo} className="mx-auto h-30 w-auto" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleLoginWithMicrosoft} className="space-y-6">
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Login With Microsoft
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RealSignUp;
