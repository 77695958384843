import { Configuration, LogLevel } from '@azure/msal-browser';

const currentHost = window.location.origin; // Use current window location as the base

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID, // Your Azure client ID from environment variables
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`, // Tenant ID from environment variables
    redirectUri: `${currentHost}/threads`, // Use the current host dynamically for redirect URI
    postLogoutRedirectUri: `${currentHost}/`, // Use the current host dynamically for post-logout redirect
    navigateToLoginRequestUri: true,
  },
  cache: {
    cacheLocation: 'localStorage', // or "sessionStorage"
    storeAuthStateInCookie: true, // Use cookies to store auth state
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        console.log('callback params azure', level, message, containsPii);
        if (containsPii) {
          return; // Don't log messages containing PII
        }

        // Log based on the log level
        switch (level) {
          case LogLevel.Error:
            console.error('Error:', message);
            break;
          case LogLevel.Info:
            console.info('Info:', message);
            break;
          case LogLevel.Verbose:
            console.debug('Verbose:', message);
            break;
          case LogLevel.Warning:
            console.warn('Warning:', message);
            break;
          default:
            console.log('Log:', message); // Default case
            break;
        }
      },
      logLevel: LogLevel.Warning, // Set appropriate log level (Warning, Error, etc.)
      piiLoggingEnabled: false, // Disable logging for PII (Personally Identifiable Information)
    },
  },
};

export const loginRequest = {
  scopes: ['User.Read'], // The scope for accessing user information
};
