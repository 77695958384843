import React, { useEffect, useState } from 'react';
import {
  Box,
  Slider,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Stack,
} from '@mui/material';
import useAxiosInstance from '../utils/axiosInstance';

const Setting = ({ setModalOpen }) => {
  const axiosInstance = useAxiosInstance();
  const [topP, setTopP] = useState(0.9); // Initial value for Top_p
  const [responseType, setResponseType] = useState('accurate'); // Initial value for dropdown (Accurate)
  const [instructions, setInstructions] = useState(''); // Initial value for Instructions textarea

  const saveKey = async (e) => {
    e.preventDefault();

    // Create the data object to be sent
    const data = {
      instructions: instructions,
      response_type: responseType,
    };

    try {
      // Use Axios instance to make the request
      const response = await axiosInstance.post('api/settings/', data);
      setModalOpen(false);
    } catch (error) {
      console.error('An error occurred while saving settings:', error.message || error);
    }
  };

  return (
    <form onSubmit={saveKey} className="flex flex-col items-center justify-center gap-2">
      <Box sx={{ width: 300 }}>
        {/* Temperature Slider */}
        <Typography gutterBottom>Temperature</Typography>
        <Slider
          aria-label="Temperature"
          defaultValue={30}
          valueLabelDisplay="auto"
          step={0.1}
          marks
          min={0}
          max={1}
        />

        {/* Top_p Number Input */}
        <TextField
          type="number"
          value={topP}
          onChange={(e) => setTopP(e.target.value)} // Update Top_p value
          variant="outlined"
          fullWidth
          inputProps={{ step: 0.01, min: 0, max: 1 }}
        />

        {/* Dropdown for Response Type (Accurate, Creative, Balanced) */}
        <FormControl fullWidth margin="normal">
          <InputLabel className="-my-2 -ml-3">Response Type</InputLabel>
          <Select value={responseType} onChange={(e) => setResponseType(e.target.value)}>
            <MenuItem value="accurate">Accurate</MenuItem>
            <MenuItem value="creative">Creative</MenuItem>
            <MenuItem value="balanced">Balanced</MenuItem>
          </Select>
        </FormControl>

        {/* Instructions Text Area */}
        <Typography gutterBottom>Instructions</Typography>
        <TextField
          multiline
          rows={4}
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          variant="outlined"
          fullWidth
        />
      </Box>

      <Stack direction="row" spacing={2} sx={{ mt: 2, width: '100%', maxWidth: 300 }}>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Save
        </Button>
      </Stack>
    </form>
  );
};

export default Setting;
