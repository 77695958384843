// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './config/authConfig';
import App from './App';
import './index.css';

const msalInstance = new PublicClientApplication(msalConfig);

// Initialize MSAL
msalInstance
  .initialize()
  .then(() => {
    // Handle the redirect flow here
    msalInstance
      .handleRedirectPromise()
      .then(() => {
        const container = document.getElementById('root');
        const root = createRoot(container);
        root.render(
          <React.StrictMode>
            <App instance={msalInstance} />
          </React.StrictMode>,
        );
      })
      .catch((error) => {
        console.error('Error handling redirect:', error);
      });
  })
  .catch((error) => {
    console.error('Error initializing MSAL:', error);
  });
