import React, { useState, useRef, useEffect } from 'react';
import ChatMessage from './ChatMessage';
import { useChatContext } from '../context/chatContext';
import { MdSend, MdAttachFile, MdClose } from 'react-icons/md';
import 'react-tooltip/dist/react-tooltip.css';
import { useParams, useNavigate } from 'react-router-dom';
import SideBar from './SideBar';
import useAxiosInstance from '../utils/axiosInstance.js';
const VERSION = '0.1.0';

const ChatView = () => {
  const messagesEndRef = useRef();
  const axiosInstance = useAxiosInstance();
  const inputRef = useRef();
  const [formValue, setFormValue] = useState('');

  const { messages, setMessages, defaultThread, isGeneratingAnswer, setIsGeneratingAnswer } =
    useChatContext(); // Access isGeneratingAnswer from context
  const [attachedFiles, setAttachedFiles] = useState([]);
  const { threadId } = useParams();
  const navigate = useNavigate();

  // Fetch chats based on threadId
  const fetchChats = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/threads/${threadId}`,
      );
      if (response.data && Array.isArray(response.data.chats)) {
        setMessages(response.data.chats);
        localStorage.setItem(`chatMessages_${threadId}`, JSON.stringify(response.data.chats));
      }
    } catch (error) {
      console.error('Error fetching chats:', error.response?.data || error.message);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const updateMessage = (text, isAI = false) => {
    setMessages((prevMessages) => {
      if (isAI && prevMessages.length > 0 && prevMessages[prevMessages.length - 1].ai) {
        // Update the last AI message
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1].text += text;
        return updatedMessages;
      } else {
        // Add a new message
        return [
          ...prevMessages,
          {
            id: Date.now(),
            createdAt: new Date().toISOString(),
            text,
            ai: isAI,
          },
        ];
      }
    });
  };

  const sendMessageToAPI = async (messageToSend, threadId) => {
    try {
      setIsGeneratingAnswer(true); // Set loading true via context
      const accessTokenKey = Object.keys(sessionStorage).find((key) => key.includes('accesstoken'));
      const sessionData = JSON.parse(sessionStorage.getItem(accessTokenKey));
      const bearerToken = sessionData;

      const url = `${process.env.REACT_APP_API_BASE_URL}/api/stream/?message=${encodeURIComponent(
        messageToSend,
      )}&thread_id=${threadId}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'text/plain',
        },
        redirect: 'follow',
      });

      if (!response.ok) {
        throw new Error(`Failed to get response from the API: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';

      const streamMessages = async () => {
        let done = false;
        let accumulatedWord = '';
        while (!done) {
          const { done: isDone, value } = await reader.read();
          done = isDone;
          if (value) {
            const chunk = decoder.decode(value, { stream: true });
            accumulatedResponse += chunk;

            // Split the chunk into words
            const words = chunk.split(/\s+/);

            for (let word of words) {
              accumulatedWord += word + ' ';

              // Add a small delay between words
              await new Promise((resolve) =>
                setTimeout(resolve, Math.floor(Math.random() * 10) + 10),
              ); // random delay from 10 to 20ms

              setMessages((prevMessages) => {
                if (prevMessages.length > 0 && prevMessages[prevMessages.length - 1].ai) {
                  // Update the last AI message with the new word
                  const updatedMessages = [...prevMessages];
                  updatedMessages[updatedMessages.length - 1].text = accumulatedWord.trim();
                  return updatedMessages;
                } else {
                  // Add a new AI message if none exists
                  return [
                    ...prevMessages,
                    {
                      id: Date.now(),
                      createdAt: new Date().toISOString(),
                      text: accumulatedWord.trim(),
                      ai: true,
                    },
                  ];
                }
              });
            }
          }
        }
      };

      await streamMessages();
    } catch (error) {
      console.error('Error sending message to API:', error.message);
      updateMessage(error.message, true);
    } finally {
      setIsGeneratingAnswer(false); // Set loading false via context
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!formValue.trim()) return;

    const cleanPrompt = formValue.trim();
    setFormValue('');
    updateMessage(cleanPrompt, false);

    await sendMessageToAPI(cleanPrompt, threadId);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (threadId) {
      // Clear old messages
      setMessages([]);

      // Optionally clear the stored messages in localStorage (if applicable)
      localStorage.removeItem(`chatMessages_${threadId}`);

      // Fetch new chats
      fetchChats();
    }
  }, [threadId]);

  useEffect(() => {
    if (threadId && messages.length > 0) {
      localStorage.setItem(`chatMessages_${threadId}`, JSON.stringify(messages));
    }
  }, [messages, threadId]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [formValue]);

  return (
    <div className="flex">
      <SideBar />
      <div className="chatview flex-grow w-[70%]">
        <main className="chatview__chatarea" style={{ paddingTop: '40px' }}>
          {messages &&
            messages.map((message, index) => (
              <ChatMessage key={message.id || index} message={message} />
            ))}
          <span ref={messagesEndRef}></span>
        </main>
        <form className="form" onSubmit={sendMessage}>
          <div className="relative w-full">
            {/* Display attached files */}
            {/* <div className="attached-files flex flex-wrap space-x-2 my-2">
              {attachedFiles.map((file) => (
                <div key={file.id} className="relative w-20 h-20 flex-shrink-0">
                  {file.type.startsWith('image/') ? (
                    <img
                      src={file.url}
                      alt={file.name}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  ) : (
                    <div className="file-preview w-full h-full bg-gray-100 flex items-center justify-center rounded-lg p-2 border border-gray-300">
                      <span className="text-xs text-center truncate px-1">{file.name}</span>
                    </div>
                  )}
                  <button
                    className="absolute -top-2 -right-2 bg-red-500 text-white p-1 rounded-full"
                    onClick={() => removeFile(file.id)}
                  >
                    <MdClose size={16} />
                  </button>
                </div>
              ))}
            </div> */}
            {/* Container for the textarea and icon */}
            <div className="flex items-center relative">
              <textarea
                ref={inputRef}
                className={`chatview__textarea-message pl-3 ${
                  !defaultThread ? 'cursor-not-allowed' : ''
                }`}
                rows={1}
                style={{ height: 'auto', minHeight: '50px', maxHeight: '150px' }}
                value={formValue}
                onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && sendMessage(e)}
                onChange={(e) => setFormValue(e.target.value)}
                disabled={!defaultThread || isGeneratingAnswer} // Use isGeneratingAnswer
              />

              <button
                type="submit"
                className={`chatview__btn-send ${
                  !formValue || isGeneratingAnswer || !defaultThread ? 'cursor-not-allowed' : ''
                }`}
                // disabled={!formValue || isGeneratingAnswer || !defaultThread} // Use isGeneratingAnswer
              >
                {isGeneratingAnswer ? <div className="loading-spinner" /> : <MdSend size={30} />}
              </button>

              {/* <MdAttachFile
                size={24}
                onClick={() => document.getElementById('fileUpload').click()}
                style={{
                  position: 'absolute',
                  left: '15px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
              /> */}
            </div>

            {/* Hidden File Input */}
            {/* <div>
              <input
                type="file"
                id="fileUpload"
                multiple
                style={{ display: 'none' }}
                onChange={handleFileUpload}
              />
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChatView;
