import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import {
  MsalProvider,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from './config/authConfig';
import { ChatContextProvider } from './context/chatContext';
import ChatView from './components/ChatView';
import RealSignUp from './components/RealSignUp';
import Home from './components/Home';

function App({ instance }) {
  return (
    <MsalProvider instance={instance}>
      <ChatContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/threads" replace />} />
            <Route
              path="/threads/:threadId"
              element={
                <MsalAuthenticationTemplate
                  interactionType={InteractionType.Redirect}
                  authenticationRequest={loginRequest}
                >
                  <ChatView />
                </MsalAuthenticationTemplate>
              }
            />

            <Route
              path="/threads/"
              element={
                <MsalAuthenticationTemplate
                  interactionType={InteractionType.Redirect}
                  authenticationRequest={loginRequest}
                >
                  <ChatView />
                </MsalAuthenticationTemplate>
              }
            />
            <Route
              path="/home"
              element={
                <MsalAuthenticationTemplate
                  interactionType={InteractionType.Redirect}
                  authenticationRequest={loginRequest}
                >
                  <Home />
                </MsalAuthenticationTemplate>
              }
            />

            <Route
              path="/signup"
              element={
                <UnauthenticatedTemplate>
                  <RealSignUp />
                </UnauthenticatedTemplate>
              }
            />
          </Routes>
        </Router>
      </ChatContextProvider>
    </MsalProvider>
  );
}

export default App;
