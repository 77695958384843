import React, { useState } from 'react';

const Modal2 = ({ modalOpen, setModalOpen, handleSave }) => {
  const [title, setTitle] = useState('');

  if (!modalOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75">
      <div className="relative bg-white rounded-lg w-[500px] p-6">
        {/* Close button moved to the top-right corner */}
        <button
          className="absolute top-2 right-4 text-2xl text-#e5e7eb"
          onClick={() => setModalOpen(false)}
        >
          &times;
        </button>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl text-#e5e7eb font-semibold">Write your title</h2>
        </div>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter chat title"
          className="w-full p-2 mb-4 text-black bg-transparent border border-gray-300 rounded focus:outline-none focus:border-blue-500"
        />
        <div className="flex justify-end space-x-2">
          <button
            className="bg-[#1976D2] text-white px-4 py-2 rounded cursor-pointer"
            onClick={() => handleSave(title)}
            disabled={!title.trim()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal2;
